<template>
  <div class="view-forget-the-password">
    <div class="forget-middle">
      <el-form :model="forgetThePasswordData" :rules="rules" ref="forgetThePasswordData" label-width="110px"
        class="demo-ruleForm">
        <el-form-item label="手机号" prop="mobile">
          <el-input oninput="value=value.replace(/^\.+|[^\d.]/g,'')" v-model="forgetThePasswordData.mobile"
            placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="mobile_verify_code">
          <el-input v-model="forgetThePasswordData.mobile_verify_code" style="width:500px" placeholder="请输入验证码">
          </el-input>
          <button class="getCode" @click.prevent="getCode()">{{yzmtext}}</button>
        </el-form-item>
        <el-form-item label="密码" prop="new_pwd">
          <el-input v-model="forgetThePasswordData.new_pwd" type="password" autocomplete="off"
            placeholder="密码要求为8-16位数字、英文字母、特殊符号中至少两种类型以上的组合">
          </el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="ralPassword">
          <el-input v-model="forgetThePasswordData.ralPassword" type="password" autocomplete="off"
            placeholder="请保持与上次密码输入一致"></el-input>
        </el-form-item>
      </el-form>
      <div class="button-okz">
        <button class="go-one" @click="submit('forgetThePasswordData')">保存</button>
      </div>
    </div>
    <el-dialog title="" :visible.sync="dialogVisible" width="30%">
      <div class="el-log">
        <img src="../assets/images/tongguo.png" alt="">
        <span class="green">修改成功</span>
        <span class="g-to">请您用新的密码重新登录~</span>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import {
    sendPhoneCode,
    mallBusinessForgetPwd
  } from '../api/index'
  export default {
    name: 'forgetThePassword',
    data() {
      const testPassword = /(?![A-Z]*$)(?![a-z]*$)(?![0-9]*$)(?![^a-zA-Z0-9]*$)/
      var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'))
        } else if (!testPassword.test(value)) {
          callback(new Error('密码格式有误，请重新输入'))
        } else {
          if (this.forgetThePasswordData.ralPassword !== '') {
            this.$refs.forgetThePasswordData.validateField('ralPassword')
          }
          callback()
        }
      }
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'))
        } else if (value !== this.forgetThePasswordData.new_pwd) {
          callback(new Error('两次输入密码不一致!'))
        } else {
          callback()
        }
      }
      return {
        forgetThePasswordData: {
          mobile: '',
          mobile_verify_code: '',
          ralPassword: '',
          new_pwd: ''
        },
        btnBool: false,
        dialogVisible: false,
        yzmtext: '获取验证码',
        rules: {
          mobile: [{
              required: true,
              message: '请输入手机号',
              trigger: 'change'
            },
            {
              pattern: '^[1][3,4,5,6,7,8,9][0-9]{9}$',
              message: '请输入正确的手机号码',
              trigger: 'change'
            }
          ],
          mobile_verify_code: [{
            required: true,
            message: '请输入验证码',
            trigger: 'change'
          }],
          new_pwd: [{
              required: true,
              validator: validatePass,
              trigger: 'change'
            },
            {
              min: 8,
              max: 16,
              message: '密码长度不正确',
              trigger: 'change'
            }
          ],
          ralPassword: [{
              required: true,
              validator: validatePass2,
              trigger: 'change'
            },
            {
              min: 8,
              max: 16,
              message: '密码长度不正确',
              trigger: 'change'
            }

          ]
        }
      }
    },
    methods: {
      submit(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            let obj = JSON.parse(JSON.stringify(this.forgetThePasswordData))
            obj.new_pwd = this.$md5(obj.new_pwd)
            delete obj.ralPassword //删除不需要的值
            const res = await mallBusinessForgetPwd(obj)
            if (res.flag === true) {
              localStorage.clear()
              this.dialogVisible = true
              var timers = setTimeout(() => {
                this.$router.push({
                  path: '/homemain',
                  name: 'Homemain'
                })
                this.dialogVisible = false
                clearInterval(timers)
              }, 500)
            } else {
              this.$message.error(res.msg)
            }
          } else {
            return false
          }
        })
      },
      // 点击获取验证码封装
      onCode() {
        this.btnBool = true
        let i = 60
        var timer = setInterval(() => {
          this.yzmtext = '' + i + 's'
          i--
          if (i < 0) {
            this.btnBool = false
            this.yzmtext = '获取验证码'
            clearInterval(timer)
          }
        }, 1000)
      },
      // 获取验证码
      async getCode() {
        if (this.yzmtext != '获取验证码') return
        if (this.forgetThePasswordData.mobile === '') {
          this.$message.error('请输入手机号')
          return
        }
        const res = await sendPhoneCode({
          mobile: this.forgetThePasswordData.mobile,
          scene_type: 2
        })
        if (res.flag === true) {
          this.onCode()
          this.$message.success('发送成功')
        } else {
          this.$message.error(res.msg)
        }
      }
    }
  }
</script>
<style lang="less" scoped>
  .view-forget-the-password {
    background: #f9f9f9;
    padding: 60px 90px;

    .forget-middle {
      background: #fff;
      padding: 30px 500px;

      /deep/ .el-form-item__label {
        font-weight: 600;
        color: rgb(56, 55, 55);
        font-size: 16px;
      }

      /deep/ .el-form-item__content {
        display: flex;
      }

      .button-okz {
        padding-left: 300px;
        display: flex;
        flex-direction: column;

        .go-one {
          width: 280px;
          color: #6e5121;
          font-size: 18px;
          opacity: 0.7;
          border-radius: 8px;
          background: #cda156;
          height: 44px;
          // text-align: center;
          line-height: 44px;
          border: none;
          margin-top: 20px;
        }
      }

      .getCode {
        padding: 0 20px;
        background: #cda156;
        color: #fff;
        text-align: center;
        border: none;
        white-space: nowrap;
      }
    }

    .el-log {
      display: flex;
      flex-direction: column;
      align-items: center;

      .green {
        font-size: 24px;
        font-weight: 600;
        color: #52d31d;
        padding: 10px 0;
      }

      .g-to {
        font-size: 16px;
        font-weight: 400;
        color: #777;
      }
    }
  }
</style>
